import React from "react";
import client from "lib/ApolloClient";
import DataList from "components/list/DataList";
import MyGradeDisplayComp from "components/cust/MyGradeDisplayComp";
import GraphqlFragment from "lib/GraphqlFragment";
import { ApolloProvider } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import MyGradeQueryGroupComp from "components/cust/MyGradeQueryGroupComp";
import { inject, observer } from "mobx-react";

import DBResourceDetailLightBox from "components/resource/DBResourceDetailLightBox";
import JournalResourceDetailLightBox from "components/resource/JournalResourceDetailLightBox";

const getMyGradeList = gql`
  query getMyGradeList($searchForm: MyGradeForm) {
    getMyGradeList(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        pages {
          val
          active
        }
        showNext
        showPrevious
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

@inject("appStore")
@observer
class MyGradeListComp extends React.Component {
  constructor(props) {
    super(props);
    let { resourceConfig } = props.appStore;
    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";
    let isDatabases =
      props.appStore.globalConfig["jumperrwd.common.isDatabases"];
    let isJournals = props.appStore.globalConfig["jumperrwd.common.isJournals"];
    let ispjournals = props.appStore.globalConfig["jumper.common.ispjournals"];
    let isEbooks = props.appStore.globalConfig["jumperrwd.common.isEbooks"];
    this.state = {
      auth: props.readerStore.auth,
      dbID: "",
      journalID: "",
      resourceType: defaultResourceType,
      showFilter: false,
      refetch: null,
      total: 0,
      modalVisible: false,
      exportModalVisible: false,
      showDatabase: isDatabases === "1",
      showJournal: isJournals === "1" || ispjournals === "1",
      showEbook: isEbooks === "1",
    };
  }

  UNSAFE_componentWillReceiveProps(props) {}

  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  onQueryCompleted(data) {
    let { info } = data.getMyGradeList;
    let { total } = info;

    if (total !== this.state.total) {
      this.setState({ total: total });
    }
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  handleClick(type, item) {
    if (type === "databases") {
      this.setState({ dbID: item.id });
      this.dbDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=databases&id=" + item.id
      );
    } else if (type === "journal") {
      this.setState({ journalID: item.id });
      this.journalDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=" + item.resourceType + "&id=" + item.id
      );
    }
  }

  render() {
    let { t, searchForm, appStore } = this.props;
    let { dbID, journalID, showFilter, showDatabase, showJournal, showEbook } =
      this.state;
    let { resourceConfig } = appStore;

    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";

    if (searchForm.resourceType === undefined || searchForm.resourceType === "")
      searchForm.resourceType = defaultResourceType;

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <div className={showFilter ? "filter_search show_up" : "filter_search"}>
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={this.props.t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <MyGradeQueryGroupComp
            ref={(c) => (this.queryGroup = c)}
            {...this.props}
            changeShowFilter={this.changeShowFilter}
            refetch={this.state.refetch}
            showFilter={showFilter}
          />
        </div>
        <div className="search_result">
          <a
            className="accesskey"
            href="javascript:;"
            id="aC"
            accessKey="C"
            title={this.props.t("jumperrwd.common.mainContenctArea")}>
            :::
          </a>
          <h2>{t("jumperrwd.common.myGrade")}</h2>
          <div className="tabSet">
            <ul className="tabs">
              {showDatabase && (
                <li
                  className={
                    searchForm.resourceType === "databases" ? "active" : ""
                  }>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      navigate(
                        "/custList?pageType=myGrade&resourceType=databases"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(
                          "/custList?pageType=myGrade&resourceType=databases"
                        );
                      }
                    }}>
                    {t("jumperrwd.common.myGradedDB")}
                  </a>
                </li>
              )}
              {showJournal && (
                <li
                  className={searchForm.resourceType === "journal" && "active"}>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      navigate(
                        "/custList?pageType=myGrade&resourceType=journal"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(
                          "/custList?pageType=myGrade&resourceType=journal"
                        );
                      }
                    }}>
                    {t("jumperrwd.common.myGradedJournal")}
                  </a>
                </li>
              )}
              {showEbook && (
                <li className={searchForm.resourceType === "ebook" && "active"}>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      navigate("/custList?pageType=myGrade&resourceType=ebook");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(
                          "/custList?pageType=myGrade&resourceType=ebook"
                        );
                      }
                    }}>
                    {t("jumperrwd.common.myGradedEbook")}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <button
            type="button"
            tabIndex="0"
            className="filter_search_btn"
            aria-label={this.props.t("jumperrwd.common.filterButton")}
            onClick={this.changeShowFilter.bind(this, true)}>
            <i className="i_filter"></i>
          </button>
          <div className="list_all">
            <DataList
              query={getMyGradeList}
              variables={{
                searchForm: {
                  custKey: this.props.searchForm.custKey,
                  resourceType:
                    this.props.searchForm.resourceType === undefined ||
                    this.props.searchForm.resourceType === ""
                      ? defaultResourceType
                      : this.props.searchForm.resourceType,
                  limit: this.props.searchForm.limit,
                  pageNo: this.props.searchForm.pageNo,
                  sort: this.props.searchForm.sort,
                  order: this.props.searchForm.order,
                },
              }}
              fetchPolicy={"network-only"}
              displayComp={MyGradeDisplayComp}
              pagination={"FixedPagination"}
              getRefetch={this.getRefetch}
              onQueryCompleted={this.onQueryCompleted}
              showNoData={true}
              refetchQueryGroup={
                this.queryGroup ? this.queryGroup.state.refetch : null
              }
              handleClick={this.handleClick}
            />
          </div>
        </div>
        <DBResourceDetailLightBox
          ref={(c) => {
            this.dbDetailLightBox = c;
          }}
          id={dbID}
          resourceType={"databases"}
          {...this.props}
        />
        <JournalResourceDetailLightBox
          ref={(c) => {
            this.journalDetailLightBox = c;
          }}
          id={journalID}
          resourceType={searchForm.resourceType}
          {...this.props}
        />
      </ApolloProvider>
    );
  }
}

export default MyGradeListComp;
