import React from "react";
import qs from "query-string";
import navigate from "lib/navigate";
import { autobind } from "react-decoration";
import KeyboardInput from "components/form/KeyboardInput";
import GraphqlFragment from "lib/GraphqlFragment";
import gql from "graphql-tag";
import DataList from "components/list/DataList";
import SearchHistoryDisplayComp from "components/cust/SearchHistoryDisplayComp";
import { inject, observer } from "mobx-react";
import DBResourceDetailLightBox from "components/resource/DBResourceDetailLightBox";
import JournalResourceDetailLightBox from "components/resource/JournalResourceDetailLightBox";

const getSearchHistory = gql`
  query getSearchHistory($searchForm: SearchForm) {
    getSearchHistory(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

@inject("appStore")
@observer
class SearchHistoryComp extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;

    this.custKeyInput = React.createRef();
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {
    this.getQueryState(props);
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  getQueryState(props) {
    let { search } = props;
    let params = { ...search };
    let { resourceType } = params;
    if (resourceType === undefined) {
      resourceType = "3days";
    }

    let state = {
      resourceType: resourceType,
    };

    return state;
  }

  @autobind
  onSubmit(e) {
    e.preventDefault();
    let { search } = this.props;
    let params = { ...search };
    let custKeyInput = this.custKeyInput.current.val();

    if (custKeyInput === "") {
      alert(this.props.t("jumperrwd.common.pleaseEnterSearchword"));
      return;
    }

    params.custKey = custKeyInput;
    delete params.pageNo;

    navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  changeRangeTab(resourceType, e) {
    e.preventDefault();
    let { search } = this.props;
    let params = { ...search };
    params.resourceType = resourceType;

    delete params.custKey;
    this.custKeyInput.current.val("");

    this.setState({
      resourceType: resourceType,
    });

    navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  handleClick(type, item) {
    if (type === "databases") {
      this.setState({ dbID: item.id });
      this.dbDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=databases&id=" + item.id
      );
    } else if (type === "journal") {
      this.setState({ journalID: item.id, resourceType2: item.resourceType });
      this.journalDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=" + item.resourceType + "&id=" + item.id
      );
    }
  }

  render() {
    let { t } = this.props;
    let { dbID, journalID } = this.state;
    return (
      <>
        <div className="filter_search">
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={this.props.t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <div className="close"></div>
          <h2>{t("jumperrwd.common.filterCondition")}</h2>
          <div className="keyword ">
            <h3>{this.props.t("jumper.common.mycollect.filterbyname")}</h3>
            <form onSubmit={this.onSubmit}>
              <div className="form_grp form_inline">
                <KeyboardInput
                  ref={this.custKeyInput}
                  name="custKey"
                  id="mustSameAsId"
                  type="text"
                  title={t("jumperrwd.common.enterKeyword")}
                  placeholder={t("jumperrwd.common.enterKeyword")}
                />
                <input
                  name=""
                  type="submit"
                  title={t("jumperrwd.sdi.filter")}
                  value={t("jumperrwd.sdi.filter")}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="search_result">
          <a
            className="accesskey"
            href="javascript:;"
            id="aC"
            accessKey="C"
            title={this.props.t("jumperrwd.common.mainContenctArea")}>
            :::
          </a>
          <h2>{this.props.t("jumper.common.searchhistory")}</h2>
          {/*<button type="button" className="filter_search_btn">
            <i className="i_filter"></i>
          </button>*/}
          <div className="tabSet">
            <ul className="tabs">
              <li
                className={this.state.resourceType === "3days" ? "active" : ""}>
                <a
                  tabIndex="0"
                  onClick={this.changeRangeTab.bind(this, "3days")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeRangeTab("3days", e);
                    }
                  }}>
                  {this.props.t("jumper.common.table.3days")}
                </a>
              </li>
              <li
                className={this.state.resourceType === "1week" ? "active" : ""}>
                <a
                  tabIndex="0"
                  onClick={this.changeRangeTab.bind(this, "1week")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeRangeTab("1week", e);
                    }
                  }}>
                  {this.props.t("jumper.common.table.1week")}
                </a>
              </li>
              <li
                className={
                  this.state.resourceType === "1month" ? "active" : ""
                }>
                <a
                  tabIndex="0"
                  onClick={this.changeRangeTab.bind(this, "1month")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeRangeTab("1month", e);
                    }
                  }}>
                  {this.props.t("jumper.common.table.1month")}
                </a>
              </li>
              <li className={this.state.resourceType === "all" ? "active" : ""}>
                <a
                  tabIndex="0"
                  onClick={this.changeRangeTab.bind(this, "all")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeRangeTab("all", e);
                    }
                  }}>
                  {this.props.t("jumper.common.all")}
                </a>
              </li>
            </ul>
          </div>
          <div className="list_all">
            <DataList
              query={getSearchHistory}
              variables={{
                searchForm: this.props.searchForm,
              }}
              fetchPolicy={"network-only"}
              displayComp={SearchHistoryDisplayComp}
              pagination={"FixedPagination"}
              getRefetch={this.getRefetch}
              showNoData={true}
              handleClick={this.handleClick}
            />
          </div>
        </div>
        <DBResourceDetailLightBox
          ref={(c) => {
            this.dbDetailLightBox = c;
          }}
          id={dbID}
          resourceType={"databases"}
          {...this.props}
        />
        <JournalResourceDetailLightBox
          ref={(c) => {
            this.journalDetailLightBox = c;
          }}
          id={journalID}
          resourceType={this.state.resourceType2}
          {...this.props}
        />
      </>
    );
  }
}

export default SearchHistoryComp;
